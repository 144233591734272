class Slider {
  private readonly container: HTMLElement
  private readonly slides: HTMLElement[]
  private readonly observer: IntersectionObserver
  private readonly controls: HTMLSpanElement[]
  private index: number = 0

  constructor(container: HTMLElement, slides: HTMLElement[]) {
    this.container = container
    this.slides = slides
    this.controls = this.createControls(this.slides.length)
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      root: this.container,
      rootMargin: '0px',
      threshold: 0.5,
    })

    this.slides.forEach(slide => this.observer.observe(slide))
    this.container.after(this.createNavigation())
  }

  private handleIntersect(entries: IntersectionObserverEntry[]) {
    const entry = entries.find(e => e.isIntersecting)

    if (entry) {
      this.controls[this.index].classList.remove('slider__control--active')
      this.index = this.slides.findIndex(slide => slide === entry.target)
      this.controls[this.index].classList.add('slider__control--active')
    }
  }

  private createNavigation(): HTMLDivElement {
    const navigation = document.createElement('div')
    navigation.classList.add('slider__navigation')
    navigation.append(...this.controls)

    return navigation
  }

  private createControls(itemsCount: number): HTMLSpanElement[] {
    const controls: HTMLSpanElement[] = []

    for (let i = 0; i < itemsCount; i++) {
      const element = document.createElement('span')
      element.setAttribute('tabindex', '0')
      element.classList.add('slider__control')
      element.addEventListener('click', this.navigate.bind(this, i))
      element.addEventListener('touchend', this.navigate.bind(this, i))
      controls.push(element)
    }

    return controls
  }

  navigate(value: number) {
    if (value === this.index || value > this.slides.length - 1) {
      return
    }

    this.container.scroll({
      left: this.container.scrollLeft + this.container.clientWidth * (value - this.index),
      behavior: 'smooth',
    })
  }
}

(function () {
  const welcomeSliderContainer = document.querySelector<HTMLElement>('#welcome .slider')
  const welcomeSliderItems = welcomeSliderContainer.querySelectorAll<HTMLElement>('.slider__item')
  new Slider(welcomeSliderContainer, Array.from(welcomeSliderItems))

  const soonSliderContainer = document.querySelector<HTMLElement>('#soon_summary .slider')
  const soonSliderItems = soonSliderContainer.querySelectorAll<HTMLElement>('.slider__item')
  new Slider(soonSliderContainer, Array.from(soonSliderItems))
})()
